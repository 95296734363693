import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  Grid,
  Card,
  Box,
  Typography,
  FormControlLabel,
  Switch,
} from "@mui/material";
import "./index.sass";
import ExpandLessOutlinedIcon from "@mui/icons-material/ExpandLessOutlined";
import ExpandMoreOutlinedIcon from "@mui/icons-material/ExpandMoreOutlined";
import {
  getSlots,
  getSlotsPPMC,
  updateBooking,
} from "../../services/actionSlice";
import moment from "moment";
import Header from "../../components/Header";
import { AppDispatch } from "../../storage/store";
import StyledButton from "../../components/Button/StyledButton";
import morning from "../../images/morning.png";
import afternoon from "../../images/afternoon.png";
import evening from "../../images/evening.png";
import Loader from "../../components/Loader";
import StyledButtonLight from "../../components/Button/StyledButtonLight";
interface Props {
  setOpenSlotModal: any;
  customerEmail: any;
  customerNumber: any;
  setCollectionDate: any;
  collectionDate: any;
  setCollectionSlot: any;
  collectionSlot: any;
  partnerKey: any;
  latitude: any;
  longitude: any;
  setOpenCouponModal: any;
  loading: any;
  customerPackages: any;
  reschedule: any;
  setCancel: any;
  cancel: any;
  bookingData: any;
  setOpenTracking: any;
  setOpenCancel: any;
  areaServicable: any;
  type: any;
  customerZone: any;
  pincode: any;
  checkAreaNsa:any;
  showEcg: any;
  customerDetails: any;
  isEcgCheck:any;
  addIndex:any,
  customerGender:any
}

const CollectionSlotPage: React.FC<Props> = ({
  setOpenSlotModal,
  customerEmail,
  customerNumber,
  setCollectionDate,
  collectionDate,
  setCollectionSlot,
  checkAreaNsa,
  collectionSlot,
  partnerKey,
  latitude,
  longitude,
  showEcg = false,
  setOpenCouponModal,
  loading,
  isEcgCheck,
  customerPackages,
  reschedule,
  bookingData,
  setCancel,
  cancel,
  setOpenTracking,
  setOpenCancel,
  areaServicable,
  type,
  customerZone,
  pincode,
  customerDetails,
  addIndex,
  customerGender
}) => {
  const dispatch = useDispatch<AppDispatch>();
  const centreData = useSelector((state: any) => state.webAppSlice.centreByKey);
  const slots = useSelector((state: any) => state.webAppSlice.slots);
  const slotsPPMC = useSelector((state: any) => state.webAppSlice.slotsPPMC);
  const [slotType, setSlotType] = useState<any>("morning");
  const [morningSlots, setMorningSlots] = useState<any>([]);
  const [afterNoonSlots, setAfterNoonSlots] = useState<any>([]);
  const [eveningSlots, setEveningSlots] = useState<any>([]);
  const [subTotal, setSubTotal] = useState<number>(0);
  const [pkgTotal, setPkgTotal] = useState<number>(0);
  const[isEcg,setIsEcg]=useState<boolean>(isEcgCheck || false)
  const [gender, setGender] = useState<any>(
    addIndex !=='' ? customerDetails[addIndex]?.customerGender : customerGender
  );



  useEffect(() => {
    let total = 0;
    let radtotal = 0;
    let pkTotal = customerPackages.length;

    customerPackages.length > 0 &&
      customerPackages.map(
        (val: any) =>
          (total =
            total +
            (val?.package_center_prices?.city_offer_price
              ? val?.package_center_prices?.city_offer_price
              : val?.package_center_prices?.offer_price))
      );
    customerPackages.length > 0 &&
      customerPackages.map(
        (val: any) => (radtotal = radtotal + val?.offer_price)
      );
    customerDetails.length > 0 &&
      customerDetails.map(
        (data: any) =>
          data.packages.length > 0 &&
          data.packages.map((val: any, index: any) => {
            pkTotal = pkTotal + index + 1;

            total =
              total +
              (val?.package_center_prices?.city_offer_price
                ? val?.package_center_prices?.city_offer_price
                : val?.package_center_prices?.offer_price);
          })
      );
    if (type === "rad") {
      setSubTotal(radtotal);
    } else {
      setSubTotal(total);
    }
    setPkgTotal(pkTotal);
  }, [customerPackages, customerDetails]);
  const handleClose = () => {
    if (
      reschedule &&
      collectionDate === bookingData.collection_date &&
      collectionSlot?.id === bookingData?.collection_slot?.id
    ) {
      alert(
        "Can't select same time slot with same date which has been last chosen, it should be different."
      );
    } else {
      let slot =
        (collectionSlot?.format_12_hrs?.start_time
          ? collectionSlot?.format_12_hrs?.start_time
          : collectionSlot?.start_time_12hr) +
        "-" +
        (collectionSlot?.format_12_hrs?.end_time
          ? collectionSlot?.format_12_hrs?.end_time
          : collectionSlot?.end_time_12hr);
      //@ts-ignore
      gtag("event", "slot_info_next_btn", {
        collection_date: collectionDate,
        collection_slot: slot,
        user_pk: customerNumber || customerEmail,
        cart_value: subTotal,
        member_count: customerDetails.length + 1,
        is_coupon_enable: centreData?.can_create_coupon,
        pincode: pincode,
        is_coupon_auto_apply: false,
      });
      setOpenCouponModal(true);
      setOpenSlotModal(false);
    }
  };
  
  useEffect(() => {
    if (
      collectionDate &&
      collectionDate >= moment().format("YYYY-MM-DD") &&
      partnerKey?.center_key &&
      latitude &&
      longitude && areaServicable
    ) {

 
        dispatch(
          getSlotsPPMC({
            url: `?collection_date=${collectionDate}${
              customerZone ? `&customer_zone=${customerZone}` : ""
            }${
              gender === "female" ? "&ecg_n_female=true" : ""
            }`,
            key: partnerKey?.center_key,
            type: type,
          })
        );

        dispatch(
          getSlots({
            url: `?collection_date=${collectionDate}&latitude=${latitude}&longitude=${longitude}${
              type === "rad" && customerZone ? `&customer_zone=1` : ""
            }${
              centreData.custom_user_requirement_for === "ACCENTURE" &&
              !areaServicable &&
              type !== "rad"
                ? "&booking_type=accenture&force=true"
                : ""
            }`,
            key: partnerKey?.center_key,
            type: type,
          })
        );
   
      

     

      //@ts-ignore
      gtag("event", "pv_slot_info", {
        package_selection: pkgTotal,
        pincode: pincode,
        user_pk: customerNumber || customerEmail,
        is_reschedule: reschedule,
        is_slot_change: reschedule,
        member_count: customerDetails.length + 1,
      });
    }
  }, [collectionDate, partnerKey, latitude, customerZone,areaServicable]);
  useEffect(() => {
    if (morningSlots.length > 0) {
      setSlotType("morning");
    } else if (afterNoonSlots.length > 0) {
      setSlotType("afternoon");
    } else if (eveningSlots.length > 0) {
      setSlotType("evening");
    } else {
      setSlotType("");
    }
  }, [afterNoonSlots, morningSlots, eveningSlots]);


  

  useEffect(() => {
    if (
      slots &&
      slots.results &&
      slots.results.length > 0 &&
      slotsPPMC &&
      slots.results.length > 0
    ) {
      let arr: any = [];
      if (isEcg) {
        arr = slotsPPMC?.results ? [...slotsPPMC.results] : [];
      } else {
        arr = slots?.results ? [...slots.results] : [];
      }
      
      let m: any = [];
      let a: any = [];
      let e: any = [];

      if (!isEcg) {
        arr.length > 0 &&
          arr?.map((val: any) => {
            if (type === "rad") {
              if (Number(String(val.start_time).slice(0, 2)) < 12) {
                m.push(val);
              } else if (
                Number(String(val.start_time).slice(0, 2)) > 11 &&
                Number(String(val.start_time).slice(0, 2)) < 18
              ) {
                a.push(val);
              } else {
                e.push(val);
              }
            } else {
              if (
                Number(String(val.format_24_hrs?.start_time).slice(0, 2)) < 12
              ) {
                m.push(val);
              } else if (
                Number(String(val.format_24_hrs?.start_time).slice(0, 2)) >
                  11 &&
                Number(String(val.format_24_hrs?.start_time).slice(0, 2)) < 18
              ) {
                a.push(val);
              } else {
                e.push(val);
              }
            }
          });
      } else {
        arr.length > 0 &&
          arr.map((val: any) => {
            const startHour = Number(val.start_time.slice(0, 2)); // Extract the hour part from start_time

            if (type === "rad") {
              // Categorize based on 24-hour format directly
              if (startHour < 12) {
                m.push(val); // Morning (before 12:00)
              } else if (startHour >= 12 && startHour < 18) {
                a.push(val); // Afternoon (12:00 to 17:59)
              } else {
                e.push(val); // Evening (18:00 onwards)
              }
            } else {
              // Categorize based on format_24_hrs if available

              const formattedStartHour = Number(val?.start_time.slice(0, 2));

              if (formattedStartHour < 12) {
                m.push(val); // Morning (before 12:00)
              } else if (formattedStartHour >= 12 && formattedStartHour < 18) {
                a.push(val); // Afternoon (12:00 to 17:59)
              } else {
                e.push(val); // Evening (18:00 onwards)
              }
            }
          });
      }

      setAfterNoonSlots(a);
      setMorningSlots(m);
      setEveningSlots(e);
    } else if(!checkAreaNsa) {
      let arr: any = [
        {
          "id": 46,
          "available_slot": 23,
          "format_24_hrs": {
            "start_time": "05:00:00",
            "end_time": "06:00:00"
          },
          "format_12_hrs": {
            "start_time": "05:00 AM",
            "end_time": "06:00 AM"
          }
        },
        {
          "id": 14,
          "available_slot": 21,
          "format_24_hrs": {
            "start_time": "06:00:00",
            "end_time": "07:00:00"
          },
          "format_12_hrs": {
            "start_time": "06:00 AM",
            "end_time": "07:00 AM"
          }
        },
        {
          "id": 2,
          "available_slot": 13,
          "format_24_hrs": {
            "start_time": "07:00:00",
            "end_time": "08:00:00"
          },
          "format_12_hrs": {
            "start_time": "07:00 AM",
            "end_time": "08:00 AM"
          }
        },
        {
          "id": 3,
          "available_slot": 19,
          "format_24_hrs": {
            "start_time": "08:00:00",
            "end_time": "09:00:00"
          },
          "format_12_hrs": {
            "start_time": "08:00 AM",
            "end_time": "09:00 AM"
          }
        },
        {
          "id": 4,
          "available_slot": 21,
          "format_24_hrs": {
            "start_time": "09:00:00",
            "end_time": "10:00:00"
          },
          "format_12_hrs": {
            "start_time": "09:00 AM",
            "end_time": "10:00 AM"
          }
        },
        {
          "id": 5,
          "available_slot": 51,
          "format_24_hrs": {
            "start_time": "10:00:00",
            "end_time": "11:00:00"
          },
          "format_12_hrs": {
            "start_time": "10:00 AM",
            "end_time": "11:00 AM"
          }
        },
        {
          "id": 6,
          "available_slot": 54,
          "format_24_hrs": {
            "start_time": "11:00:00",
            "end_time": "12:00:00"
          },
          "format_12_hrs": {
            "start_time": "11:00 AM",
            "end_time": "12:00 PM"
          }
        },
        {
          "id": 7,
          "available_slot": 53,
          "format_24_hrs": {
            "start_time": "12:00:00",
            "end_time": "13:00:00"
          },
          "format_12_hrs": {
            "start_time": "12:00 PM",
            "end_time": "01:00 PM"
          }
        },
        {
          "id": 8,
          "available_slot": 54,
          "format_24_hrs": {
            "start_time": "13:00:00",
            "end_time": "14:00:00"
          },
          "format_12_hrs": {
            "start_time": "01:00 PM",
            "end_time": "02:00 PM"
          }
        },
        {
          "id": 9,
          "available_slot": 42,
          "format_24_hrs": {
            "start_time": "14:00:00",
            "end_time": "15:00:00"
          },
          "format_12_hrs": {
            "start_time": "02:00 PM",
            "end_time": "03:00 PM"
          }
        },
        {
          "id": 10,
          "available_slot": 12,
          "format_24_hrs": {
            "start_time": "15:00:00",
            "end_time": "16:00:00"
          },
          "format_12_hrs": {
            "start_time": "03:00 PM",
            "end_time": "04:00 PM"
          }
        },
        {
          "id": 11,
          "available_slot": 12,
          "format_24_hrs": {
            "start_time": "16:00:00",
            "end_time": "17:00:00"
          },
          "format_12_hrs": {
            "start_time": "04:00 PM",
            "end_time": "05:00 PM"
          }
        },
        {
          "id": 12,
          "available_slot": 12,
          "format_24_hrs": {
            "start_time": "17:00:00",
            "end_time": "18:00:00"
          },
          "format_12_hrs": {
            "start_time": "05:00 PM",
            "end_time": "06:00 PM"
          }
        },
        {
          "id": 13,
          "available_slot": 10,
          "format_24_hrs": {
            "start_time": "18:00:00",
            "end_time": "19:00:00"
          },
          "format_12_hrs": {
            "start_time": "06:00 PM",
            "end_time": "07:00 PM"
          }
        },
        {
          "id": 15,
          "available_slot": 12,
          "format_24_hrs": {
            "start_time": "19:00:00",
            "end_time": "20:00:00"
          },
          "format_12_hrs": {
            "start_time": "07:00 PM",
            "end_time": "08:00 PM"
          }
        }
      ];
      let m: any = [];
      let a: any = [];
      let e: any = [];

        arr.length > 0 &&
          arr?.map((val: any) => {
            if (type === "rad") {
              if (Number(String(val.start_time).slice(0, 2)) < 12) {
                m.push(val);
              } else if (
                Number(String(val.start_time).slice(0, 2)) > 11 &&
                Number(String(val.start_time).slice(0, 2)) < 18
              ) {
                a.push(val);
              } else {
                e.push(val);
              }
            } else {
              if (
                Number(String(val.format_24_hrs?.start_time).slice(0, 2)) < 12
              ) {
                m.push(val);
              } else if (
                Number(String(val.format_24_hrs?.start_time).slice(0, 2)) >
                  11 &&
                Number(String(val.format_24_hrs?.start_time).slice(0, 2)) < 18
              ) {
                a.push(val);
              } else {
                e.push(val);
              }
            }
          });
      
      setAfterNoonSlots(a);
      setMorningSlots(m);
      setEveningSlots(e);
    } else{
      setAfterNoonSlots([]);
      setMorningSlots([]);
      setEveningSlots([]);
    }
  }, [slots, slotsPPMC, isEcg]);
  // console.log(collectionSlot, "collectionSlot");
  const rescheduleBooking = async () => {
    if (
      collectionDate === bookingData.collection_date &&
      collectionSlot?.id === bookingData?.collection_slot?.id
    ) {
      alert(
        "Can't select same time slot with same date which has been last chosen, it should be different."
      );
    } else {
      const data = {
        booking_status: "rescheduled",
        booking_id: bookingData?.pk,
        remark: "rescheduled",
        collection_date: collectionDate,
        collection_slot: collectionSlot.id || "",
      };

      await dispatch(
        updateBooking({
          data: data,
          key: partnerKey?.center_key,
          force:
            centreData?.custom_user_requirement_for === "ACCENTURE" &&
            !areaServicable
              ? true
              : false,
        })
      );
    }
  };
  const cancelBooking = () => {
    setOpenCancel(true);
    setOpenSlotModal(false);
  };
  // useEffect(() => {
  //   if (bookingUpdated?.booking_id && cancel) {
  //     setOpenTracking(true);
  //     setOpenSlotModal(false);
  //     setCancel(false);
  //     dispatch(resetState());
  //     dispatch(
  //       getBooking({
  //         url: `?booking_id=${bookingUpdated?.booking_id}`,
  //         key: `${partnerKey?.center_key}`,
  //       })
  //     );
  //   }
  // }, [bookingUpdated?.booking_id && cancel]);
  const selectSlot = (val: any) => {
    setCollectionSlot(val);
    let slot =
      (val?.format_12_hrs?.start_time
        ? val?.format_12_hrs?.start_time
        : val?.start_time_12hr) +
      "-" +
      (val?.format_12_hrs?.end_time
        ? val?.format_12_hrs?.end_time
        : val?.end_time_12hr);

    //@ts-ignore
    gtag("event", "time_slot_btn", {
      slot_selection: slot,
      is_enable: true,
      user_pk: customerNumber || customerEmail,
    });
  };


  return (
    <Grid className="contentCC" id={cancel ? "" : "collectionSlot"}>
      {cancel ? (
        <p style={{ margin: 15, fontWeight: "bold" }}>
          Change of Plans? Try Rescheduling
        </p>
      ) : (
        <Header
          isFilter={false}
          setOpenCouponModal={setOpenCouponModal}
          setOpenSlotModal={setOpenSlotModal}
          showHome={reschedule ? false : true}
        />
      )}
      <Box sx={{ flexGrow: 1 }}>
        <Card sx={{ borderRadius: "16px", m: 1 }}>
          <Grid container spacing={2} className="dialogContent">
            <Grid item xs={12}>
              <Typography component="p">SELECT DATE</Typography>
            </Grid>
            {(centreData.custom_user_requirement_for === "ACCENTURE" ||
              centreData.custom_user_requirement_for === "BOB" ||
              centreData.custom_user_requirement_for === "HITACHI" ||
              centreData.custom_user_requirement_for === "RECI" ||
              centreData.custom_user_requirement_for === "EVAHEL" ||
              centreData.tab_type === "RADI" ||
              centreData.tab_type === "BOTH" ||
              centreData.custom_user_requirement_for === "AON") &&
            (!areaServicable || type === "rad") ? (
              <>
                {centreData.tab_type === "RADI" ||
                centreData.tab_type === "BOTH" ? (
                  <Grid item xs={3}>
                    <div
                      className={
                        moment().add(2, "days").format("YYYY-MM-DD") ===
                        collectionDate
                          ? "datePro"
                          : "dateLite"
                      }
                      onClick={() => {
                        setCollectionDate(
                          moment().add(2, "days").format("YYYY-MM-DD")
                        );
                        setCollectionSlot("");
                      }}
                    >
                      <sub>{moment().add(2, "days").format("MMM")}</sub>
                      <p>{moment().add(2, "days").format("DD")}</p>
                      <sup>{moment().add(2, "days").format("ddd")}</sup>
                    </div>{" "}
                  </Grid>
                ) : (
                  ""
                )}
                <Grid item xs={3}>
                  <div
                    className={
                      moment().add(3, "days").format("YYYY-MM-DD") ===
                      collectionDate
                        ? "datePro"
                        : "dateLite"
                    }
                    onClick={() => {
                      setCollectionDate(
                        moment().add(3, "days").format("YYYY-MM-DD")
                      );
                      setCollectionSlot("");
                    }}
                  >
                    <sub>{moment().add(3, "days").format("MMM")}</sub>
                    <p>{moment().add(3, "days").format("DD")}</p>
                    <sup>{moment().add(3, "days").format("ddd")}</sup>
                  </div>{" "}
                </Grid>
                <Grid item xs={3}>
                  <div
                    className={
                      moment().add(4, "days").format("YYYY-MM-DD") ===
                      collectionDate
                        ? "datePro"
                        : "dateLite"
                    }
                    onClick={() => {
                      setCollectionDate(
                        moment().add(4, "days").format("YYYY-MM-DD")
                      );
                      setCollectionSlot("");
                    }}
                  >
                    <sub>{moment().add(4, "days").format("MMM")}</sub>
                    <p>{moment().add(4, "days").format("DD")}</p>
                    <sup>{moment().add(4, "days").format("ddd")}</sup>
                  </div>{" "}
                </Grid>
                <Grid item xs={3}>
                  <div
                    className={
                      moment().add(5, "days").format("YYYY-MM-DD") ===
                      collectionDate
                        ? "datePro"
                        : "dateLite"
                    }
                    onClick={() => {
                      setCollectionDate(
                        moment().add(5, "days").format("YYYY-MM-DD")
                      );
                      setCollectionSlot("");
                    }}
                  >
                    <sub>{moment().add(5, "days").format("MMM")}</sub>
                    <p>{moment().add(5, "days").format("DD")}</p>
                    <sup>{moment().add(5, "days").format("ddd")}</sup>
                  </div>{" "}
                </Grid>
                {centreData.tab_type === "RADI" ||
                centreData.tab_type === "BOTH" ? (
                  ""
                ) : (
                  <Grid item xs={3}>
                    <div
                      className={
                        moment().add(6, "days").format("YYYY-MM-DD") ===
                        collectionDate
                          ? "datePro"
                          : "dateLite"
                      }
                      onClick={() => {
                        setCollectionDate(
                          moment().add(6, "days").format("YYYY-MM-DD")
                        );
                        setCollectionSlot("");
                      }}
                    >
                      <sub>{moment().add(6, "days").format("MMM")}</sub>
                      <p>{moment().add(6, "days").format("DD")}</p>
                      <sup>{moment().add(6, "days").format("ddd")}</sup>
                    </div>{" "}
                  </Grid>
                )}
              </>
            ) : (
              <>
                {centreData.custom_user_requirement_for !== "EISAI" ? (
                  <Grid item xs={3}>
                    <div
                      className={
                        moment().format("YYYY-MM-DD") === collectionDate
                          ? "datePro"
                          : "dateLite"
                      }
                      onClick={() => {
                        setCollectionDate(moment().format("YYYY-MM-DD"));
                        setCollectionSlot("");
                      }}
                    >
                      <sub>{moment().format("MMM")}</sub>
                      <p>{moment().format("DD")}</p>
                      <sup>Tod</sup>
                    </div>{" "}
                  </Grid>
                ) : (
                  ""
                )}
                <Grid item xs={3}>
                  <div
                    className={
                      moment().add(1, "days").format("YYYY-MM-DD") ===
                      collectionDate
                        ? "datePro"
                        : "dateLite"
                    }
                    onClick={() => {
                      setCollectionDate(
                        moment().add(1, "days").format("YYYY-MM-DD")
                      );
                      setCollectionSlot("");
                    }}
                  >
                    <sub>{moment().add(1, "days").format("MMM")}</sub>
                    <p>{moment().add(1, "days").format("DD")}</p>
                    <sup>Tom</sup>
                  </div>
                </Grid>
                <Grid item xs={3}>
                  <div
                    className={
                      moment().add(2, "days").format("YYYY-MM-DD") ===
                      collectionDate
                        ? "datePro"
                        : "dateLite"
                    }
                    onClick={() => {
                      setCollectionDate(
                        moment().add(2, "days").format("YYYY-MM-DD")
                      );
                      setCollectionSlot("");
                    }}
                  >
                    <sub>{moment().add(2, "days").format("MMM")}</sub>
                    <p>{moment().add(2, "days").format("DD")}</p>
                    <sup>{moment().add(2, "days").format("ddd")}</sup>
                  </div>{" "}
                </Grid>
                <Grid item xs={3}>
                  <div
                    className={
                      moment().add(3, "days").format("YYYY-MM-DD") ===
                      collectionDate
                        ? "datePro"
                        : "dateLite"
                    }
                    onClick={() => {
                      setCollectionDate(
                        moment().add(3, "days").format("YYYY-MM-DD")
                      );
                      setCollectionSlot("");
                    }}
                  >
                    <sub>{moment().add(3, "days").format("MMM")}</sub>
                    <p>{moment().add(3, "days").format("DD")}</p>
                    <sup>{moment().add(3, "days").format("ddd")}</sup>
                  </div>{" "}
                </Grid>
                {centreData.custom_user_requirement_for !== "EISAI" ? (
                  ""
                ) : (
                  <Grid item xs={3}>
                    <div
                      className={
                        moment().add(4, "days").format("YYYY-MM-DD") ===
                        collectionDate
                          ? "datePro"
                          : "dateLite"
                      }
                      onClick={() => {
                        setCollectionDate(
                          moment().add(4, "days").format("YYYY-MM-DD")
                        );
                        setCollectionSlot("");
                      }}
                    >
                      <sub>{moment().add(4, "days").format("MMM")}</sub>
                      <p>{moment().add(4, "days").format("DD")}</p>
                      <sup>{moment().add(4, "days").format("ddd")}</sup>
                    </div>{" "}
                  </Grid>
                )}
              </>
            )}
          </Grid>
        </Card>
        <Card sx={{ borderRadius: "16px", m: 1 }}>
          <Grid container spacing={2} className="dialogContent">
            <Grid
              item
              xs={12}
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Typography component="p">SELECT TIME SLOT</Typography>
             
            </Grid>
            {loading ? (
              <Loader />
            ) : (
              <>
                <Grid container className="borderBox">
                  {slotType === "morning" ? (
                    <>
                      <Grid item xs={10} onClick={() => setSlotType("")}>
                        <img src={morning} /> Morning
                      </Grid>
                      <Grid
                        item
                        xs={2}
                        textAlign="right"
                        onClick={() => setSlotType("")}
                      >
                        <ExpandLessOutlinedIcon />
                      </Grid>
                    </>
                  ) : (
                    <>
                      <Grid item xs={10} onClick={() => setSlotType("morning")}>
                        <img src={morning} /> Morning
                      </Grid>
                      <Grid
                        item
                        xs={2}
                        textAlign="right"
                        onClick={() => setSlotType("morning")}
                      >
                        <ExpandMoreOutlinedIcon />
                      </Grid>
                    </>
                  )}
                  {slotType === "morning" ? (
                    <Grid container>
                      {morningSlots.length > 0 &&
                        morningSlots?.map((val: any) => (
                          <Grid item xs={6} textAlign="center">
                            <Button
                              variant={
                                collectionSlot?.id === val.id
                                  ? "contained"
                                  : "outlined"
                              }
                              disabled={val?.available_slot < 1}
                              size="small"
                              color="primary"
                              onClick={() => selectSlot(val)}
                            >
                              {val?.format_12_hrs?.start_time
                                ? val?.format_12_hrs?.start_time
                                : val?.start_time_12hr}{" "}
                              -{" "}
                              {val?.format_12_hrs?.end_time
                                ? val?.format_12_hrs?.end_time
                                : val?.end_time_12hr}
                            </Button>
                          </Grid>
                        ))}
                    </Grid>
                  ) : (
                    ""
                  )}
                </Grid>

                <Grid container className="borderBox">
                  {slotType === "afternoon" ? (
                    <>
                      <Grid item xs={10} onClick={() => setSlotType("")}>
                        <img src={afternoon} /> Afternoon
                      </Grid>
                      <Grid
                        item
                        xs={2}
                        textAlign="right"
                        onClick={() => setSlotType("")}
                      >
                        <ExpandLessOutlinedIcon />
                      </Grid>
                    </>
                  ) : (
                    <>
                      <Grid
                        item
                        xs={10}
                        onClick={() => setSlotType("afternoon")}
                      >
                        <img src={afternoon} /> Afternoon
                      </Grid>
                      <Grid
                        item
                        xs={2}
                        textAlign="right"
                        onClick={() => setSlotType("afternoon")}
                      >
                        <ExpandMoreOutlinedIcon />
                      </Grid>
                    </>
                  )}

                  {slotType === "afternoon" ? (
                    <Grid container>
                      {afterNoonSlots.length > 0 &&
                        afterNoonSlots?.map((val: any) => (
                          <Grid item xs={6} textAlign="center">
                            <Button
                              variant={
                                collectionSlot?.id === val.id
                                  ? "contained"
                                  : "outlined"
                              }
                              disabled={val?.available_slot < 1}
                              size="small"
                              color="primary"
                              onClick={() => selectSlot(val)}
                            >
                              {val?.format_12_hrs?.start_time
                                ? val?.format_12_hrs?.start_time
                                : val?.start_time_12hr}{" "}
                              -{" "}
                              {val?.format_12_hrs?.end_time
                                ? val?.format_12_hrs?.end_time
                                : val?.end_time_12hr}
                            </Button>
                          </Grid>
                        ))}
                    </Grid>
                  ) : (
                    ""
                  )}
                </Grid>

                <Grid container className="borderBox">
                  {slotType === "evening" ? (
                    <>
                      <Grid item xs={10} onClick={() => setSlotType("")}>
                        <img src={evening} /> Evening
                      </Grid>
                      <Grid
                        item
                        xs={2}
                        textAlign="right"
                        onClick={() => setSlotType("")}
                      >
                        <ExpandLessOutlinedIcon />
                      </Grid>
                    </>
                  ) : (
                    <>
                      <Grid item xs={10} onClick={() => setSlotType("evening")}>
                        <img src={evening} /> Evening
                      </Grid>
                      <Grid
                        item
                        xs={2}
                        textAlign="right"
                        onClick={() => setSlotType("evening")}
                      >
                        <ExpandMoreOutlinedIcon />
                      </Grid>
                    </>
                  )}
                  {slotType === "evening" ? (
                    <Grid container>
                      {eveningSlots.length > 0 &&
                        eveningSlots?.map((val: any) => (
                          <Grid item xs={6} textAlign="center">
                            <Button
                              variant={
                                collectionSlot?.id === val.id
                                  ? "contained"
                                  : "outlined"
                              }
                              disabled={val?.available_slot < 1}
                              size="small"
                              color="primary"
                              onClick={() => selectSlot(val)}
                            >
                              {val?.format_12_hrs?.start_time
                                ? val?.format_12_hrs?.start_time
                                : val?.start_time_12hr}{" "}
                              -{" "}
                              {val?.format_12_hrs?.end_time
                                ? val?.format_12_hrs?.end_time
                                : val?.end_time_12hr}
                            </Button>
                          </Grid>
                        ))}
                    </Grid>
                  ) : (
                    ""
                  )}
                </Grid>
              </>
            )}
            <Grid item xs={12}>
              {centreData.custom_user_requirement_for === "AON" ? (
                <sub style={{ color: "green" }}>
                  *The window to avail health checkup will be opened from 12th
                  July 2023 to 12th August 2023.
                </sub>
              ) : (
                ""
              )}
            </Grid>
          </Grid>
        </Card>
      </Box>
      {cancel ? (
        <Grid container>
          <Grid item xs={6}>
            <div style={{ margin: "0 15px 15px" }}>
              <StyledButtonLight onClick={cancelBooking} disabled={loading}>
                Cancel
              </StyledButtonLight>
            </div>
          </Grid>
          <Grid item xs={6}>
            <div style={{ margin: "0 15px 15px" }}>
              <StyledButton
                onClick={rescheduleBooking}
                disabled={
                  collectionDate === "" || collectionSlot === "" || loading
                }
              >
                Reschedule
              </StyledButton>
            </div>
          </Grid>
        </Grid>
      ) : reschedule ? (
        <Grid container>
          <Grid item xs={12}>
            <div style={{ margin: "0 15px 15px" }}>
              <StyledButton
                onClick={handleClose}
                disabled={collectionDate === "" || collectionSlot === ""}
              >
                NEXT
              </StyledButton>
            </div>
          </Grid>
        </Grid>
      ) : (
        <Grid container>
          <Grid item xs={6}>
            <div style={{ margin: "0 15px 15px" }}>
              <StyledButtonLight onClick={() => setOpenSlotModal(false)}>
                BACK
              </StyledButtonLight>
            </div>
          </Grid>
          <Grid item xs={6}>
            <div style={{ margin: "0 15px 15px" }}>
              <StyledButton
                onClick={handleClose}
                disabled={collectionDate === "" || collectionSlot === ""}
              >
                NEXT
              </StyledButton>
            </div>
          </Grid>
        </Grid>
      )}
      {centreData.custom_user_requirement_for === "ACCENTURE" ||
      centreData.custom_user_requirement_for === "BOB" ? (
        <Grid
          item
          xs={12}
          textAlign={"center"}
          style={{ marginBottom: "10px" }}
        >
          <hr style={{ border: "1px solid #ececec" }} />
          <sup>
            For any queries/concerns, please write us at
            <a href="mailto:appointments@redcliffelabs.com">
              <b> appointments@redcliffelabs.com</b>
            </a>
          </sup>
        </Grid>
      ) : (
        ""
      )}
      {centreData?.datetime_additional_text ? (
        <Grid
          item
          xs={12}
          textAlign={"center"}
          style={{ marginBottom: "50px" }}
        >
          <hr style={{ border: "1px solid #ececec" }} />
          <sup>{centreData?.datetime_additional_text}</sup>
        </Grid>
      ) : (
        ""
      )}
    </Grid>
  );
};

export default CollectionSlotPage;
